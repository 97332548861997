import React, { useContext, useState } from "react";
import { Typography, Tabs, Tab, Box } from "@mui/material";
import TabPanel from "../../components/generic/TabPanel";
import CommentFeed from "../../components/comments/CommentFeed";
import LangContext from "../../contexts/LangContext";

const UserComments = () => {
    // const [tabVal, setTabVal] = useState(0);
    const { langTokens } = useContext(LangContext);

    return (
        <div
            style={{
                padding: "2rem",
                // overflow: "auto",
                // position: "fixed",
                height: "90%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    maxWidth: "60vw",
                }}>
                <Typography
                    variant="h4"
                    align="left"
                    style={{ paddingBottom: "2rem" }}>
                    {langTokens.your_comments}
                </Typography>
                <CommentFeed type={"all"} />
            </div>

            {/* start of mod feature */}
            {/* <Box>
                <Tabs
                    value={tabVal}
                    onChange={(_, newVal) => {
                        setTabVal(newVal);
                    }}>
                    <Tab label={langTokens.approved} value={0} />
                    <Tab label={langTokens.rejected} value={1} />
                    <Tab label={langTokens.pending} value={2} />
                </Tabs>
                <TabPanel value={tabVal} index={0}>
                    <CommentFeed type={"approved"} />
                </TabPanel>
                <TabPanel value={tabVal} index={1}>
                    <CommentFeed type={"rejected"} />
                </TabPanel>
                <TabPanel value={tabVal} index={2}>
                    <CommentFeed type={"pending"} />
                </TabPanel>
            </Box> */}
            {/* end of mod feature */}
        </div>
    );
};

export default UserComments;
