import React from "react";
import Cookies from "js-cookie";

/* 
    Wrapper function for the backend fetch calls that 
    updates the local access token if it is updated
*/
const apiFetch = async (url, options = {}) => {
    console.log(url);
    console.log(localStorage.getItem("access").slice(-5));
    const response = await fetch(url, options);

    // Check for new access cookies
    const newAccess = Cookies.get("new_access");

    // Set new cookie in localStorage and remove it
    if (newAccess) {
        console.log(newAccess.slice(-5));
        localStorage.setItem("access", newAccess);
        Cookies.remove("new_access");
    }

    // Tokens are invalid; Must log-in again
    if (response && response.status === 401) {
        localStorage.removeItem("access");
    }
    return response;
};

export default apiFetch;
