import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import { BASE_URL } from "../theme";
import apiFetch from "../api";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [username, setUsername] = useState("");
    const [userId, setUserId] = useState(0);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [modChapters, setModChapters] = useState([]);
    const [modTags, setModTags] = useState([]);

    const fetchUserData = useCallback(async () => {
        const fetchModTags = async () => {
            try {
                const res = await apiFetch(`${BASE_URL}/reviews/tags/all/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access"
                        )}`,
                    },
                });
                const data = await res.json();
                if (res.ok) {
                    setModTags(data.results);
                }
            } catch (error) {
                console.error("Error fetching mod tags:", error);
            }
        };

        const fetchModData = async () => {
            try {
                const res = await apiFetch(
                    `${BASE_URL}/accounts/mod/profile/`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access"
                            )}`,
                        },
                    }
                );
                const data = await res.json();
                if (res.ok) {
                    setModChapters(data.chapters);
                    fetchModTags();
                }
            } catch (error) {
                console.error("Error fetching mod data:", error);
            }
        };

        try {
            const res = await apiFetch(`${BASE_URL}/accounts/profile/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });
            const data = await res.json();

            if (res.ok) {
                setUsername(data.username);
                console.log(`fetch data in user context for ${data.username}`);
                setUserId(data.id);
                setIsSuperUser(data.is_superuser);

                if (data.is_superuser) {
                    fetchModData();
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }, []);

    // useEffect(() => {
    //     if (!username || username.length === 0) {
    //         console.log("in use effect in user context");
    //         fetchUserData();
    //     }
    // }, [username]);

    return (
        <UserContext.Provider
            value={{
                username,
                setUsername,
                userId,
                setUserId,
                isSuperUser,
                setIsSuperUser,
                modChapters,
                setModChapters,
                modTags,
                setModTags,
                fetchUserData,
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
