// import logo from './logo.svg';
import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SignUp from "./pages/signup";
import Login from "./pages/login";
import Verses from "./pages/verses";
import WelcomePage from "./pages/home";
import ModSettings from "./pages/mod/settings";
import ModSignUp from "./pages/mod/signup";
import ModFeed from "./pages/mod/feed";

import NavBar from "./components/generic/NavBar";
import ErrorPage from "./components/generic/ErrorPage";

import { LoadingProvider } from "./contexts/LoadingContext";
import { ChaptersProvider } from "./contexts/ChaptersContext";
import { AuthProvider } from "./contexts/AuthContext";
import { UserProvider } from "./contexts/UserContext";
import { VersesProvider } from "./contexts/VersesContext";
import { LangProvider } from "./contexts/LangContext";
import VersePage from "./pages/singleVerse";
import UserComments from "./pages/userComments";
import PostPage from "./pages/singlePost";
import UserPosts from "./pages/userPosts";
import ScholarProfiles from "./pages/scholarProfiles";
import ModProfile from "./pages/mod/profile";

function App() {
    return (
        <LoadingProvider>
            <LangProvider>
                <UserProvider>
                    <AuthProvider>
                        <ChaptersProvider>
                            <VersesProvider>
                                <div className="App">
                                    <BrowserRouter>
                                        <Routes>
                                            <Route
                                                path="/signup/"
                                                element={<ModSignUp />}
                                                // element={<SignUp />}
                                            />
                                            <Route
                                                path="/login/"
                                                element={<Login />}
                                            />
                                            <Route
                                                path="/"
                                                element={<NavBar />}>
                                                <Route
                                                    path=""
                                                    element={<WelcomePage />}
                                                />

                                                <Route
                                                    path="post/:id/"
                                                    element={<PostPage />}
                                                />

                                                <Route
                                                    path="quran/:num/"
                                                    element={<Verses />}
                                                />

                                                <Route
                                                    path="quran/:chapNum/verse/:num/"
                                                    element={<VersePage />}
                                                />

                                                <Route
                                                    path="user/comments/"
                                                    element={<UserComments />}
                                                />

                                                <Route
                                                    path="user/posts/"
                                                    element={<UserPosts />}
                                                />

                                                <Route
                                                    path="mod/settings/"
                                                    element={<ModSettings />}
                                                />

                                                {/* <Route
                                                    path="mod/create/"
                                                    element={<ModSignUp />}
                                                /> */}

                                                <Route
                                                    path="mod/feed/"
                                                    element={<ModFeed />}
                                                />

                                                <Route
                                                    path="profile/"
                                                    element={<ModProfile />}
                                                />

                                                <Route
                                                    path="scholars/"
                                                    element={
                                                        <ScholarProfiles />
                                                    }
                                                />
                                            </Route>
                                            <Route element={<ErrorPage />} />
                                        </Routes>
                                    </BrowserRouter>
                                </div>
                            </VersesProvider>
                        </ChaptersProvider>
                    </AuthProvider>
                </UserProvider>
            </LangProvider>
        </LoadingProvider>
    );
}

export default App;
