import React, { useState, useContext } from "react";
import UserContext from "../../../contexts/UserContext";
import "../../../pages/singleVerse/singleVerse.css";
import {
    Avatar,
    Alert,
    Card,
    Button,
    TextField,
    Typography,
    Box,
} from "@mui/material";

import PostContext from "../../../contexts/PostContext";
import AddVersesGroup from "../../generic/AddVersesGroup";
import { BASE_URL } from "../../../theme";
import { useNavigate } from "react-router-dom";
import LangContext from "../../../contexts/LangContext";
import apiFetch from "../../../api";

const CommentThreadForm = ({
    postId,
    dispatch = null,
    parent = null,
    mod = false,
    nested = false,
    displayFunc = null,
    successFunc = null,
    submitFunc = null,
}) => {
    const styleNested = {
        marginTop: "1rem",
        marginBottom: "1rem",
        marginLeft: "1rem",
        border: "none",
        boxShadow: "none",
    };
    const styleOutter = {
        marginTop: "1rem",
        border: "none",
        boxShadow: "none",
    };

    const { username, userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);
    const { state } = useContext(PostContext);

    const [text, setText] = useState("");
    const [refs, setRefs] = useState({});

    const [success, setSuccess] = useState(false);
    const [displayForm, setDisplayForm] = useState(false);

    const [errors, setErrors] = useState({});
    let navigate = useNavigate();

    const submitComm = () => {
        if (text.length === 0) {
            setErrors({ ...errors, comment: langTokens.comment_err });
        } else {
            let commVerses = Object.keys(refs).map((k) => refs[k].verses);
            commVerses = commVerses.flat(1);

            apiFetch(`${BASE_URL}/comments/add/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
                body: JSON.stringify({
                    text: text,
                    user: userId,
                    post: postId,
                    chapters: Object.keys(refs).map((k) => parseInt(k)),
                    verses: commVerses,
                    parent: parent,
                }),
            }).then((res) => {
                if (res.status !== 201) {
                    return Promise.reject(res);
                }
                setText("");
                setRefs({});

                if (dispatch) {
                    dispatch({
                        type: "SET_FIELD",
                        field: "submitted",
                        value: true,
                    });
                }

                if (displayFunc) {
                    displayFunc(false);
                    successFunc(true);
                } else {
                    setSuccess(true);
                    setDisplayForm(false);

                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                }
            });
        }
    };

    return (
        <div>
            <Card sx={nested ? styleNested : styleOutter}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.8rem",
                    }}>
                    <Avatar sx={{ bgcolor: "#D27D2D" }}>
                        {username !== undefined &&
                            username[0] !== undefined &&
                            username[0].toUpperCase()}
                    </Avatar>
                    <Box style={{ width: "100%" }}>
                        <TextField
                            variant="standard"
                            multiline
                            fullWidth
                            align="left"
                            InputProps={{
                                style: { fontSize: 14, minRows: 2 },
                            }}
                            placeholder={langTokens.comment_placeholder}
                            onClick={() => setDisplayForm(true)}
                            onChange={(e) => setText(e.target.value)}
                        />

                        {(displayFunc || displayForm) && (
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                {!mod && (
                                    <AddVersesGroup
                                        style={{ marginRight: "auto" }}
                                        verse={state.currVerse}
                                        postRefs={refs}
                                        setPostRefs={setRefs}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                )}
                                <Box style={{ marginLeft: "auto" }}>
                                    <Button
                                        onClick={() => {
                                            setDisplayForm(false);
                                            if (displayFunc) {
                                                displayFunc(false);
                                            }
                                        }}>
                                        <Typography fontSize={12}>
                                            {langTokens.cancel}
                                        </Typography>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            if (userId === 0) {
                                                navigate("/login");
                                            } else {
                                                mod
                                                    ? submitFunc(text)
                                                    : submitComm();
                                                setText("");
                                            }
                                        }}>
                                        <Typography fontSize={12}>
                                            {langTokens.comment}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Card>

            {/* start of mod feature */}
            {/* 
            {success && (
                <Alert severity="success" onClose={() => setSuccess(false)}>
                    {langTokens.comment_alert}
                </Alert>
            )} */}
            {/* end of mod feature */}
        </div>
    );
};

export default CommentThreadForm;
