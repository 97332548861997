import { Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import LoadingScreen from "../../components/generic/LoadingScreen";

import "./home.css";
import cmply_logo from "../../media/cmply_logo.png";
import LoadingContext from "../../contexts/LoadingContext";
import ChaptersContext from "../../contexts/ChaptersContext";
import ChapterList from "../../components/quran/ChapterList";
import TopVerses from "../../components/quran/TopVerses";
import LangContext from "../../contexts/LangContext";
import ControversialVerses from "../../components/quran/ControversialVerses";

const WelcomePage = () => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { langTokens } = useContext(LangContext);
    const { chapters, fetchChapters } = useContext(ChaptersContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (chapters.length === 0) {
                    fetchChapters();
                }
                setIsLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [chapters.length, fetchChapters, setIsLoading]);

    return (
        <div className="home-page">
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    <div className="home-title">
                        <img
                            src={cmply_logo}
                            alt="compassionately logo"
                            width={"80"}
                        />
                        <Typography
                            color="primary"
                            align="center"
                            fontSize={44}>
                            {langTokens.welcome_home}
                        </Typography>
                        <Typography fontSize={20} color="#808080">
                            {langTokens.welcome_desc}
                        </Typography>
                    </div>
                    {/* <TopVerses /> */}
                    <ControversialVerses />
                    <Typography color="primary">
                        {langTokens.explore_posts}
                    </Typography>
                    <ChapterList />
                </>
            )}
        </div>
    );
};

export default WelcomePage;
