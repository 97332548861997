import React, { useContext, useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";

import VersePost from "../VersePost";
import "./verseposts.css";
import UserContext from "../../../contexts/UserContext";
import PostContext from "../../../contexts/PostContext";
import LangContext from "../../../contexts/LangContext";
const VersePosts = ({ userSpecific = false }) => {
    const { userId } = useContext(UserContext);
    const { state, dispatch } = useContext(PostContext);
    const { langTokens } = useContext(LangContext);
    const { posts, numLikes, numDislikes } = state;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        let nLikes = {};
        let nDislikes = {};

        let likedLst = [];
        let dislikedLst = [];

        for (let i = 0; i < posts.length; i++) {
            let v1 = posts[i].likes;
            if (v1.find((e) => e.user === userId)) {
                // the user already liked this post
                likedLst.push(posts[i].id);
            }

            nLikes[posts[i].id] = v1.length;

            let v2 = posts[i].dislikes;
            if (v2.find((e) => e.user === userId)) {
                // the user already disliked this post
                dislikedLst.push(posts[i].id);
            }
            nDislikes[posts[i].id] = v2.length;
        }

        dispatch({ type: "SET_FIELD", field: "numLikes", value: nLikes });
        dispatch({ type: "SET_FIELD", field: "numDislikes", value: nDislikes });
        dispatch({ type: "SET_FIELD", field: "liked", value: likedLst });
        dispatch({ type: "SET_FIELD", field: "disliked", value: dislikedLst });
    }, [posts, userId, dispatch]);

    const sortBy = (metric) => {
        if (metric === "likes") {
            dispatch({
                type: "SET_FIELD",
                field: "posts",
                value: []
                    .concat(posts)
                    .sort((a, b) => numLikes[b.id] - numLikes[a.id]),
            });
        } else if (metric === "dislikes") {
            dispatch({
                type: "SET_FIELD",
                field: "posts",
                value: []
                    .concat(posts)
                    .sort((a, b) => numDislikes[b.id] - numDislikes[a.id]),
            });
        } else if (metric === "newest") {
            dispatch({
                type: "SET_FIELD",
                field: "posts",
                value: []
                    .concat(posts)
                    .sort(
                        (a, b) =>
                            Date.parse(b.date_created) -
                            Date.parse(a.date_created)
                    ),
            });
        } else if (metric === "oldest") {
            dispatch({
                type: "SET_FIELD",
                field: "posts",
                value: []
                    .concat(posts)
                    .sort(
                        (a, b) =>
                            Date.parse(a.date_created) -
                            Date.parse(b.date_created)
                    ),
            });
        }
    };

    return (
        <div className="verse-posts">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                }}>
                <Button
                    id="basic-button"
                    onClick={(e) => setAnchorEl(e.currentTarget)}>
                    {langTokens.sort_by}
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={() => setAnchorEl(null)}>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            sortBy("oldest");
                        }}>
                        {langTokens.oldest_first}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            sortBy("newest");
                        }}>
                        {langTokens.newest_first}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            sortBy("likes");
                        }}>
                        {langTokens.top_liked}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            sortBy("dislikes");
                        }}>
                        {langTokens.most_dislikes}
                    </MenuItem>
                </Menu>
            </div>
            {posts.map((post, i) => (
                <div className="verse-post" key={"verse-post-" + i}>
                    <VersePost post={post} />
                </div>
            ))}
        </div>
    );
};

export default VersePosts;
