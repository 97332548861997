import { Box, Card, CardActionArea, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import ChaptersContext from "../../../contexts/ChaptersContext";
import { useNavigate } from "react-router-dom";
import LangContext from "../../../contexts/LangContext";
import { BASE_URL } from "../../../theme";

const ControversialVerses = () => {
    const [verses, setVerses] = useState([]);
    const { chapters, fetchChapters } = useContext(ChaptersContext);
    const { lang, langTokens } = useContext(LangContext);

    let navigate = useNavigate();

    useEffect(() => {
        const fetchVerses = async () => {
            try {
                if (chapters.length === 0) {
                    await fetchChapters();
                }

                const response = await fetch(
                    `${BASE_URL}/quran/verses/controversial/?limit=12`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                const data = await response.json();
                setVerses(data.results);
            } catch (error) {
                console.error("Error fetching verses:", error);
            }
        };
        fetchVerses();
    }, [chapters, fetchChapters]);

    return (
        <div style={{ paddingBottom: "2rem" }}>
            <Typography variant="h6" sx={{ padding: "0.7rem" }}>
                {langTokens.most_disc_verses}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.2rem",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                {verses.map((verse, i) => (
                    <Card key={`top-${i}`} sx={{ flex: "0 0 30%" }}>
                        <CardActionArea
                            onClick={() =>
                                navigate(
                                    `/quran/${verse.chapter}/verse/${verse.num}`
                                )
                            }>
                            <Typography
                                fontSize={14}
                                color="primary"
                                fontWeight={"bold"}>
                                {`${
                                    lang === "en"
                                        ? chapters.find(
                                              (c) => c.id === verse.chapter
                                          ).name_eng
                                        : chapters.find(
                                              (c) => c.id === verse.chapter
                                          ).name_ara
                                }, ${langTokens.verse} ${verse.num}`}
                            </Typography>
                            <Typography fontSize={14} fontStyle={"italic"}>
                                {lang === "en"
                                    ? `${verse.posts.length} ${
                                          verse.posts.length === 1
                                              ? langTokens.interpretation
                                              : langTokens.interpretations
                                      }`
                                    : `${
                                          verse.posts.length === 1
                                              ? langTokens.interpretation
                                              : langTokens.interpretations
                                      } ${verse.posts.length}`}
                            </Typography>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </div>
    );
};

export default ControversialVerses;
