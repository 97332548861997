import React, { createContext, useState, useEffect, useContext } from "react";
import { BASE_URL } from "../theme";
import UserContext from "./UserContext";
const AuthContext = createContext({
    isTokenSet: false,
    setIsTokenSet: () => {},
});

export const AuthProvider = ({ children }) => {
    const [isTokenSet, setIsTokenSet] = useState(
        localStorage.getItem("access") ? true : false
    );
    const { fetchUserData } = useContext(UserContext);

    const loginUser = async (username, password) => {
        try {
            const res = await fetch(`${BASE_URL}/accounts/login/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            });
            const data = await res.json();
            if (res.ok) {
                localStorage.setItem("access", data.access);
                setIsTokenSet(true);
                return true;
            } else {
                logOut();
                return false;
            }
        } catch (error) {
            console.error("Error logging in user:", error);
        }
    };

    const logOut = () => {
        setIsTokenSet(false);
        localStorage.removeItem("access");
        // localStorage.removeItem("refresh");
    };
    useEffect(() => {
        if (isTokenSet) {
            fetchUserData();
        }
    }, [isTokenSet, fetchUserData]);
    // useEffect(() => {
    //     const refreshToken = async () => {
    //         try {
    //             const res = await fetch(`${BASE_URL}/accounts/token/refresh/`, {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify({
    //                     refresh: localStorage.getItem("refresh"),
    //                 }),
    //             });
    //             const data = await res.json();

    //             if (res.ok) {
    //                 console.log("refresh token success");
    //                 setIsTokenSet(true);

    //                 localStorage.setItem("access", data.access);
    //                 // localStorage.setItem("refresh", data.refresh);
    //             } else {
    //                 console.log("refresh token failed");
    //                 logOut();
    //             }
    //         } catch (error) {
    //             console.error("Error refreshing token:", error);
    //         }
    //     };

    //     if (isTokenSet) {
    //         const interval = setInterval(() => {
    //             refreshToken();
    //         }, 4 * 60 * 1000); // Refresh every 4 minutes
    //         return () => clearInterval(interval);
    //     }
    // }, [isTokenSet]);

    return (
        <AuthContext.Provider
            value={{
                isTokenSet,
                setIsTokenSet,
                loginUser,
                logOut,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
