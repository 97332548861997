import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/UserContext";
import LoadingContext from "../../contexts/LoadingContext";
import LoadingScreen from "../../components/generic/LoadingScreen";
import UserPost from "../../components/posts/UserPost";
import { Box, LinearProgress, Typography } from "@mui/material";
import "./userPosts.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { BASE_URL } from "../../theme";
import LangContext from "../../contexts/LangContext";
import apiFetch from "../../api";

const UserPosts = () => {
    const { userId } = useContext(UserContext);
    const { isLoading } = useContext(LoadingContext);
    const { langTokens } = useContext(LangContext);

    const [posts, setPosts] = useState([]);
    const [next, setNext] = useState(null);

    const loadMore = async () => {
        try {
            const response = await apiFetch(next, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });

            const data = await response.json();

            setPosts((lst) => [...lst, ...data.results]);

            data.next ? setNext(data.next) : setNext(null);
        } catch (error) {
            console.error("Error fetching more user posts:", error);
        }
    };
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await apiFetch(
                    `${BASE_URL}/posts/user/${userId}/?limit=10`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access"
                            )}`,
                        },
                    }
                );

                const data = await response.json();
                setPosts(data.results);
                data.next ? setNext(data.next) : setNext(null);
            } catch (error) {
                console.error("Error fetching user posts:", error);
            }
        };

        fetchPosts();
    }, [userId]);

    return (
        <div className="user-posts-page">
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <InfiniteScroll
                    dataLength={posts.length}
                    next={loadMore}
                    hasMore={next}
                    loader={<LinearProgress />}>
                    <Box className="user-posts">
                        <Typography
                            variant="h4"
                            align="left"
                            style={{
                                padding: "2rem",
                                paddingLeft: 0,
                                marginRight: "auto",
                            }}>
                            {langTokens.your_posts}
                        </Typography>

                        {posts.map((p) => (
                            <UserPost
                                key={`user-post-${p.id}`}
                                post={p}
                                posts={posts}
                                setPosts={setPosts}
                            />
                        ))}
                    </Box>
                </InfiniteScroll>
            )}
        </div>
    );
};

export default UserPosts;
