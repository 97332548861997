import React, {
    createContext,
    useState,
    useEffect,
    useMemo,
    useContext,
} from "react";
import LoadingContext from "./LoadingContext";

const LangContext = createContext({
    lang: "",
    setLang: () => {},
    langTokens: {},
    setLangTokens: () => {},
});

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState("en");
    const [langTokens, setLangTokens] = useState({});
    const { setIsLoading } = useContext(LoadingContext);

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(
                    `/locales/${lang}/translation.json`
                );
                const data = await response.json();
                setLangTokens(data);
            } catch (error) {
                console.log("Error fetching language tokens:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTokens();
    }, [lang, setIsLoading]);

    const value = useMemo(
        () => ({ lang, setLang, langTokens }),
        [lang, langTokens]
    );

    return (
        <LangContext.Provider value={value}>{children}</LangContext.Provider>
    );
};

export default LangContext;
