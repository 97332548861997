import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import LangContext from "../../../contexts/LangContext";
import UserContext from "../../../contexts/UserContext";
import "../../../pages/singleVerse/singleVerse.css";
import Comment from "../Comment";
import { BASE_URL } from "../../../theme";
import apiFetch from "../../../api";

const CommentThread = ({
    inputComments,
    state,
    dispatch,
    margin = 5,
    setup = false,
}) => {
    const { userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);

    const [currComments, setCurrComments] = useState([]);
    const [initComments] = useState(inputComments);
    const [setupComm] = useState(setup);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        function buildCommTree(parent) {
            for (let i = 0; i < initComments.length; i++) {
                if (initComments[i].parent === parent.id) {
                    parent.responses.push({ ...initComments[i] });
                }
            }
            for (let i = 0; i < parent.responses.length; i++) {
                buildCommTree(parent.responses[i]);
            }
        }

        const setupThreads = () => {
            let nLikes = {};
            let nDislikes = {};

            let likedLst = [];
            let dislikedLst = [];

            for (let i = 0; i < initComments.length; i++) {
                let v1 = initComments[i].likes;
                let v2 = initComments[i].dislikes;

                nLikes[initComments[i].id] = v1.length;

                nDislikes[initComments[i].id] = v2.length;

                if (v1.find((e) => e.user === userId)) {
                    // the user already liked this comment
                    likedLst.push(initComments[i].id);
                }

                if (v2.find((e) => e.user === userId)) {
                    // the user already disliked this comment
                    dislikedLst.push(initComments[i].id);
                }
                initComments[i].responses = [];
            }

            dispatch({ type: "SET_FIELD", field: "numLikes", value: nLikes });
            dispatch({
                type: "SET_FIELD",
                field: "numDislikes",
                value: nDislikes,
            });
            dispatch({ type: "SET_FIELD", field: "liked", value: likedLst });
            dispatch({
                type: "SET_FIELD",
                field: "disliked",
                value: dislikedLst,
            });

            const threadedList = initComments.filter((c) => c.parent === null);

            for (let i = 0; i < threadedList.length; i++) {
                buildCommTree(threadedList[i]);
            }

            setCurrComments(threadedList);
        };

        if (setupComm) {
            setupThreads();
        } else {
            setCurrComments(initComments);
        }
    }, [userId, initComments, setupComm, dispatch]);

    const styleOuter = {
        border: "none",
        boxShadow: "none",
    };

    const styleInner = {
        boxShadow: "none",
        marginLeft: margin,
    };

    const delComment = (id) => {
        apiFetch(`${BASE_URL}/comments/del/${id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                }
                setCurrComments(currComments.filter((c) => c.id !== id));
            })
            .catch((err) => console.log(err));
    };

    const sortBy = (metric) => {
        if (metric === "likes") {
            setCurrComments(
                []
                    .concat(currComments)
                    .sort((a, b) => b.likes.length - a.likes.length)
            );
        } else if (metric === "dislikes") {
            setCurrComments(
                []
                    .concat(currComments)
                    .sort((a, b) => b.dislikes.length - a.dislikes.length)
            );
        } else if (metric === "newest") {
            setCurrComments(
                []
                    .concat(currComments)
                    .sort(
                        (a, b) =>
                            Date.parse(b.date_created) -
                            Date.parse(a.date_created)
                    )
            );
        }
    };

    return (
        <div>
            {currComments !== undefined && currComments.length > 0 && (
                <Box style={setup ? styleOuter : styleInner}>
                    {setup && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}>
                            <Button
                                id="basic-button"
                                style={{ marginLeft: "auto" }}
                                onClick={(e) => setAnchorEl(e.currentTarget)}>
                                {langTokens.sort_by}
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                onClose={() => setAnchorEl(null)}>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        sortBy("newest");
                                    }}>
                                    {langTokens.newest_first}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        sortBy("likes");
                                    }}>
                                    {langTokens.top_liked}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        sortBy("dislikes");
                                    }}>
                                    {langTokens.most_dislikes}
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                    {currComments.map((comment, i) => (
                        <Comment
                            key={comment.id}
                            comment={comment}
                            delComment={delComment}
                            state={state}
                            dispatch={dispatch}
                            margin={margin}
                        />
                    ))}
                </Box>
            )}
        </div>
    );
};

export default CommentThread;
