import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { BASE_URL, CARD_WIDTH } from "../../theme";
import LangContext from "../../contexts/LangContext";

const ScholarProfiles = () => {
    const location = useLocation();

    const [profiles, setProfiles] = useState([]);

    const { langTokens } = useContext(LangContext);

    useEffect(() => {
        fetch(`${BASE_URL}/accounts/bio/all/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setProfiles(
                    data.results.sort((a, b) =>
                        a.user.username.localeCompare(b.user.username)
                    )
                );
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (location.hash && profiles.length > 0) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location, profiles]);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}>
            <Typography sx={{ padding: "2rem" }} align="left" variant="h3">
                {langTokens.scholars}
            </Typography>

            {profiles.map((p, i) => (
                <Card
                    id={`${p.user.username}`}
                    key={`profile-${i}`}
                    sx={{ width: CARD_WIDTH, margin: "1rem" }}>
                    <CardHeader
                        title={
                            <Typography align="left">
                                {p.user.username}
                            </Typography>
                        }
                    />

                    <CardContent>
                        <Typography align="left">{`${
                            p.description !== ""
                                ? p.description
                                : langTokens.nothing_here
                        }`}</Typography>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default ScholarProfiles;
